<template>
  <div>
    <a-modal
      title="添加/编辑客户"
      :visible="visible"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form :form="form" class="form">
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="客户姓名">
          <a-input placeholder="请输入客户姓名" v-decorator="['name', {rules: [{required: true, message: '请输入客户姓名'}]}]"/>
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="客户电话">
          <a-input placeholder="请输入客户电话" v-decorator="['mobile', {rules: [{required: true, message: '请输入客户电话'}]}]"/>
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="客户来源">
          <a-input placeholder="请输入客户来源" v-decorator="['csource', {rules: [{required: true, message: '请输入客户来源'}]}]"/>
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="小区名称">
          <a-input placeholder="请输入小区名称" v-decorator="['village', {rules: [{required: true, message: '请输入小区名称'}]}]"/>
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="门牌号">
          <a-input placeholder="请输入门牌号" v-decorator="['number', {rules: [{required: true, message: '请输入门牌号'}]}]"/>
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="具体地址">
          <a-input placeholder="请输入具体地址" v-decorator="['address', {rules: [{required: true, message: '请输入具体地址'}]}]"/>
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="期望售价">
          <a-input placeholder="请输入期望售价" v-decorator="['money', {rules: [{required: true, message: '请输入期望售价'}]}]"/>
        </a-form-item>
        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="客户类型">
          <a-radio-group button-style="solid" v-decorator="['type']">
            <a-radio-button :value="0">
              还未确认
            </a-radio-button>
            <a-radio-button :value="1">
              有房出售
            </a-radio-button>
            <a-radio-button :value="2">
              有房出租
            </a-radio-button>
            <a-radio-button :value="3">
              我要买房
            </a-radio-button>
            <a-radio-button :value="4">
              我要租房
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import store from '@/store'
import matchFun from '@/utils/matchFun'
import { mapState } from 'vuex'
export default {
  components: {
  },
  data () {
    return {
      visible: false,
      confirmLoading: false,
      matchFun: matchFun,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      item: null,
      form: this.$form.createForm(this)
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    moment,
    showModal (item) {
      console.log(222)
      this.item = null
      if (item !== null) {
        this.item = item
        setTimeout(() => {
          this.form.setFieldsValue({
            name: item.name,
            mobile: item.mobile,
            csource: item.csource,
            type: item.type,
            village: item.village,
            number: item.number,
            address: item.address,
            money: item.money
          })
        })
      } else {
        setTimeout(() => {
          this.form.setFieldsValue({
            name: '',
            mobile: '',
            csource: '',
            type: 0
          })
        })
      }
      this.visible = true
    },
    handleOk (e) {
      console.log(222)
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          var upData = {...values}
          upData.id = this.item !== null ? this.item.id : null
          upData.userId = this.userInfo.userId
          console.log(upData)
          this.$api.customer.save(upData).then(res => {
            if (res.status === 0) {
              this.$message.success('保存成功')
              this.$emit('ok')
              this.handleCancel()
            } else {
              this.$message.warn(res.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      })
    },
    handleCancel (e) {
      this.visible = false
    }
  }
}
</script>
